<template>
  <div class="view-header view-header--protocol-versions" v-if="pageTitle">
    <div class="view-header__header">
      <Pagination />
    </div>
    <div class="view-header__footer">
      <BtnBack :to="{ name : 'protocol', id: $route.params.id}" />
      <h1 class="page-title" v-html="pageTitle"></h1>
    </div>
  </div>
  <div class="view-body">
    <Section class="section section--list">
      <List
        :api="list.api"
        :header="list.headers"
        :items="list.col"
        :item-key="list.key"
        :disableCheckbox="true"
        :disableActions="!helperService.userHasPermission('log_list')"
      >
        <template v-slot:version="{ item }">
          Version {{ item.version }}
        </template>

        <template v-slot:date="{ item }">
          {{ new Date(item?.date).toLocaleString() }}
        </template>

        <template v-slot:ressource="{ item }">
          {{ item.ressource?.mode_appl_phyto ? 'Oui' : 'Non' }}
        </template>

        <template v-slot:actions>
          <ToolDropdown>
            <MenuItem
              v-if="helperService.userHasPermission(`log_list`)"
              :item-data="{
                  label: 'Voir les logs',
                  route: {
                    name: 'ressourceLogs',
                    params: {
                      ressource: 'protocolVersions',
                      rid: 'protocolVersions',
                      ressourceid: $route.params.id,
                      from: 'protocolVersions',
                      title: `Voir les logs`,
                    },
                  },
                  iconName: 'clipboard-list',
                }"
            />
          </ToolDropdown>

        </template>
      </List>
    </Section>
  </div>

  <NavigationDropdownProtocol />
</template>

<script>
import Section from '@/components/layout/Section.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import BtnBack from '@/components/base/BtnBack.vue'
import NavigationDropdownProtocol
  from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'

export default {
  name: 'ProtocolVersionsView',

  components: {
    NavigationDropdownProtocol,
    MenuItem,
    ToolDropdown,
    Pagination,
    BtnBack,
    List,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    const validationSchema = this.yup.object({
      description: this.yup.string().nullable(),
      categorie_id: this.yup.object().nullable(),
      media_id: this.yup.object().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      console.log(values)
      this.handleSubmit(values)
    })

    return {
      search: {
        attributs: ['titre'],
        filters: {},
      },

      list: {
        api: {
          endpoint: `protocole/${this.$route.params.id}/version`,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Version', dataField: 'version' },
          { label: 'Date' },
          { label: 'Opérateur' },
        ],
        col: ['version', 'date', 'ressource'],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
        protocolChoice: false,
        addFile: false,
      },
      protocol: {},
      validationSchema,
      onSubmit,
      isSubmitting,
      errors,
    }
  },

  mounted() {
    this.getProtocol()
  },

  methods: {
    setMedia(event) {
      this.file = event
    },

    getProtocol() {
      this.fetchService.get(`protocole/${this.$route.params.id}`).then((response) => {
        this.protocol = response.data
      })
    },

    handleSubmit(values) {
      this.emitter.emit('open-loader')

      this.fetchService.post('fichier', {
        description: values.description,
        categorie_id: values.categorie_id.key,
        media_id: values.media_id.id,
      }).then((response) => {
        this.fetchService.put(`protocole/${this.$route.params.id}/fichier`, {
          fichiers: [
            {
              id: response.data.id,
            },
          ],
        }).then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le fichier a bien été ajouté.',
          })
        })
      })

      this.modal.addFile = false
      this.emitter.emit('close-loader')
    },
  },
}
</script>

<style scoped>
</style>
